
  export default {
    props: {
      jumbotron: {
        type: Object,
        default: () => [],
        required: false,
      },
      jumbotronContainer: {
        type: Object,
        default: () => [],
        required: false,
      },
      icon: {
        type: Object,
        default: () => [],
        required: false,
      },
      img: {
        type: Object,
        default: () => [],
        required: false,
      },
      lead: {
        type: Object,
        default: () => [],
        required: false,
      },
      heading: {
        type: Object,
        default: () => [],
        required: false,
      },
      subheading: {
        type: Object,
        default: () => [],
        required: false,
      },
      body: {
        type: Array,
        default: () => [],
        required: false,
      },
      buttonPrimary: {
        type: Object,
        default: () => [],
        required: false,
      },
    },
  };
